


















import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';
import { Input, InputTextType } from './model';
import { formattedPrice } from '@/util/helpers';

@Component
export default class TextInput extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @BooleanProp()
  private readonly error!: boolean;

  @BooleanProp()
  private readonly password!: boolean;

  @BooleanProp()
  private readonly noTrim!: boolean;

  @StringProp()
  private readonly placeholder?: string;

  @StringProp()
  private readonly type?: string;

  @StringProp()
  private readonly dataElementId?: string;

  @BooleanProp()
  private readonly usePriceFormat?: boolean;

  @BooleanProp()
  private readonly clearable?: boolean; // Optional prop to toggle clear button

  public pristine = true;

  private get model(): string {
    return this.value === undefined || this.value === null ? '' : String(this.value);
  }

  private set model(value: string) {
    if (this.usePriceFormat) {
      this.$emit('update', formattedPrice(value));
    } else {
      this.$emit('update', value);
    }
  }

  public get normalizedValue(): string {
    return this.noTrim || this.password ? this.model : this.model.trim();
  }

  public get empty(): boolean {
    return this.normalizedValue === '';
  }

  private get inputType(): string | InputTextType {
    if (this.type) {
      return this.type;
    }
    if (this.password) {
      return InputTextType.PASSWORD;
    }
    return InputTextType.TEXT;
  }

  private clearInput(): void {
    this.$emit('update', ''); // Clear the input value
  }
}
